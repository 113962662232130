.Layout {
    padding: 0px;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto 2fr;
    grid-template-areas: ". . ." ". child ." ". . .";
}

.Layout-Child {
    grid-area: child;
}