.PlayBackgammon {
    position: relative;
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
}

.PlayBackgammon svg {
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
}

.PlayBackgammon .overlay {
    position: absolute;
    top: 0;
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto 1fr;
    display: grid;
    grid-template-areas: ". . ." ". child ." ". . .";
}

.PlayBackgammon .overlay .child {
    grid-area: child;
    text-align: center;
}

.PlayBackgammon .roll-button-container button {
    font-size: 2rem;
}

.PlayBackgammon .undo-container {
    position: absolute;
    top: 50%;
    left: 30%;
}

.PlayBackgammon .undo-container button {
    font-size: 2rem;
}