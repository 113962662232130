.games-list {
    display: grid;
    grid-gap: 10px;
    place-items: center;
    grid-template-columns: repeat(auto-fit,  120px);
    max-width: 90vw;
}

.games-list a {
    text-align: center;
}

.games-list img {
    width: 120px;
    height: 120px;
}